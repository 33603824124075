import React from 'react'
import classes from './HelperLabel.module.css'
export default function HelperLabel() {
    return (
        <div className={classes.HelperLabel}>
            <p className={classes.HelperText}>A : Add User</p>
            <p className={classes.HelperText}>PC : Partnership And Commission</p>
            <p className={classes.HelperText}>S :Statement</p>
            <p className={classes.HelperText}>PL :Profit Loss</p>
            <p className={classes.HelperText}>I :View Info</p>
            <p className={classes.HelperText}>P :Change Password</p>
            <p className={classes.HelperText}>D-W :Free Chip In Out</p>
            <p className={classes.HelperText}>C :Credit</p>
            <p className={classes.HelperText}>F :Change Fullname</p>
            <p className={classes.HelperText}>L :Login Activity</p>
            <p className={classes.HelperText}>Se :Close settlement</p>
        </div>

    )
}
