const settings = {
    API_URL: process.env.REACT_APP_API,
    PROD_URL: process.env.REACT_APP_PROD_API,
    SOCKET_URL: process.env.REACT_APP_ODDS_API,
    SOCKET_DEV_URL: process.env.REACT_APP_ODDS_DEV_API,
    ODDS_API_URL: `${process.env.REACT_APP_ODDS_API}api`,
    ODDS_DEV_API: process.env.REACT_APP_ODDS_DEV_API,
    NODE_ENV: process.env.NODE_ENV,
};

export default settings;