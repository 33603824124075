import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DateTime from '../../../../UI/DateTime/DateTime'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search";
import axios from '../../../../../axios-instance/backendAPI'
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    InputBase,
} from "@material-ui/core"
import { useSelector } from 'react-redux';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

    const headCells = [
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Action"
        },
        {
            id: "time",
            numeric: false,
            disablePadding: false,
            label: "Time_Of_Action"
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status"
        },
    ]

    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.heading}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        overflow: "scroll",
        [theme.breakpoints.down("sm")]: {
            overflow: "auto"
        },
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    heading: {
        padding: "2px",
        fontWeight: "bold",
        fontSize: "0.8em",
        backgroundColor: "#e7e7e7",
        border: "1px solid #8a8a82",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid black",
        float: "right",
        marginBottom: '10px',
        width: "18%",
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(3),
            width: "30%",
            height: "30px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            '& svg': {
                fontSize: 'var(--very_large_font)', // Font size for input
            },
        }
    },
    actionButton: {
        margin: 3,
        cursor: "pointer",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        fontSize: 'var(--large_font)',
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: `calc(${theme.spacing(4)}px)`,
            fontSize: 'var(--small_font)',
        },
    },
}));

export default function UserActivity() {

    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("website");
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [bufferRows, setBufferRows] = useState([])
    const [search, setSearch] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const token = useSelector(state => state.auth.token)
    const params = useParams()

    const onSubmit = (from, to, value) => {

        axios.get('/user/UserActivity/' + params.username + '/' + from + '/' + to, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {
                if (response.data.data) {
                    setBufferRows(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {

        const columns = bufferRows[0] && Object.keys(bufferRows[0])
        let newRows = search ? bufferRows.filter(bufferRow =>
            columns.some(
                column => bufferRow[column]?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
            )) : bufferRows

        setRows(newRows)

    }, [search, bufferRows])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const filter = []

    const columns = [
        {
            id: "action",
            align: "center",
        },
        {
            id: "time",
            align: "center",
            minWidth: '150px'
        },
        {
            id: "status",
            align: "center",
        },
    ]

    return (
        <div>
            <DateTime
                filter={filter}
                onSubmit={onSubmit}
                name={'Login activity of ' + params.username}
            />
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            onChange={handleSearch}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="medium"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {!rows.length ? (
                                    <TableRow>
                                        <TableCell>No data</TableCell>
                                    </TableRow>
                                ) : null}
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.time}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            padding="normal"
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            { column.id === 'time' ?
                                                                moment(value).format('DD-MM-YYYY HH:mm:ss')
                                                                : column.id === 'location' ?
                                                                    row.region + ' ' + row.city + ',' + row.country
                                                                    : value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

        </div>
    )
}