import React, { useState, useEffect } from 'react'
import DateTime from '../../../UI/DateTime/DateTime'
import PropTypes from "prop-types"
import { lighten, makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import axios from '../../../../axios-instance/backendAPI'
import moment from "moment"
import BetHistory from '../BetHistory/BetHistory'
import clsx from 'clsx';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TableSortLabel, Paper, InputBase, Checkbox, Typography, Toolbar,
    IconButton, Popover,
} from "@material-ui/core"
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { formatCoins } from '../../../../helpers/common'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let headCells = [
        {
            id: "event",
            numeric: false,
            disablePadding: false,
            label: "Event",
        },
        {
            id: "market",
            numeric: true,
            disablePadding: false,
            label: "Market",
        },
        {
            id: "Profit_Loss",
            numeric: true,
            disablePadding: false,
            label: "Profit/Loss",
        },
        {
            id: "commission",
            numeric: true,
            disablePadding: false,
            label: "Commission",
        },
        {
            id: "winner",
            numeric: true,
            disablePadding: false,
            label: "Declared",
        },
        {
            id: "settled_at",
            numeric: true,
            disablePadding: false,
            label: "Settled At",
        },
        {
            id: "action",
            numeric: true,
            disablePadding: false,
            label: "Action",
        }
    ];

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.heading}
                        style={headCell.id === "settled_at" ? { minWidth: 90 } : null}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {headCell.id === 'commission' && (
                                <>
                                    <IconButton
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onClick={handlePopoverOpen}
                                        style={{fontSize: '15px', width:'10px',marginLeft: '5px',padding: '0'}}
                                    >
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    </IconButton>
                                    <Popover
                                        id="mouse-over-popover"
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={(event) => {
                                            event.stopPropagation(); // Stop the event from propagating
                                            handlePopoverClose();
                                        }}
                                        disableRestoreFocus
                                    >
                                        <Typography style={{ padding: 8 }}>{props.com.bookmaker_com}% on Bookmaker loss bets and {props.com.fancy_com}% on Fancy stakes</Typography>
                                    </Popover>
                                </>
                            )}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
    
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        minHeight: '40px',
        [theme.breakpoints.down("sm")]: {
            minHeight: '30px'
        }
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    details:{
        cursor:'pointer',
        width: '77px',
        [theme.breakpoints.down("sm")]: {
            width: '100px'
        }
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, selected } = props;
    const history = useHistory()

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                null
            )}

            {numSelected > 0 ? (
                <p
                    className={classes.details}
                    onClick={() => {
                        history.push({ pathname: '/marketReport', state: selected })
                    }}
                ><i class="fas fa-info-circle"></i> Details</p>
            ) : (
                null
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: '10px',
        fontSize: 'var(--large_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        },
    },
    container: {
        padding: '0 10px 10px 10px'
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        overflow: "scroll",
        '& .MuiTableCell-root': {
            fontSize: 'var(--large_font)', // Adjust the font size as needed
        },
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
            '& .MuiTableCell-root': {
                fontSize: 'var(--small_font)', // Adjust the font size as needed
            },
        },
    },
    pagination: {
        '& .MuiTablePagination-caption': {
            fontSize: 'var(--large_font)', // Font size for caption
        },
        '& .MuiTablePagination-input': {
            fontSize: 'var(--large_font)', // Font size for input
        },
        [theme.breakpoints.down("sm")]: {
            '& .MuiTablePagination-caption': {
                fontSize: 'var(--small_font)', // Font size for caption
            },
            '& .MuiTablePagination-input': {
                fontSize: 'var(--small_font)', // Font size for input
            }, 
        }
    },
    totalCell: {
        fontWeight: 700,
    },
    cell: {
        minWidth: '250px',
        fontSize: 'var(--large_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        }
    },
    smallCell: {
        minWidth: '150px',
        fontSize: 'var(--large_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    heading: {
        padding: "2px 5px",
        fontWeight: "bold",
        fontSize: "var(--large_font)",
        lineHeight: '1rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        },
        backgroundColor: "#e7e7e7",
        border: "1px solid #8a8a82",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid black",
        float: "right",
        width: "18%",
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(3),
            width: "30%",
            height: "30px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            '& svg': {
                fontSize: 'var(--very_large_font)', // Font size for input
            },
        }
    },
    actionButton: {
        margin: 3,
        cursor: "pointer",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        fontSize: 'var(--large_font)',
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: `calc(${theme.spacing(4)}px)`,
            fontSize: 'var(--small_font)',
        },
    },
}));

export default function ProfitLoss() {

    const classes = useStyles()
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("website")
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([])
    const [bufferRows, setBufferRows] = useState([])
    const [search, setSearch] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [commission, setCommission] = useState({})
    const [bets, setBets] = useState(false)
    const [eventInfo, setEventInfo] = useState(null)
    const [fromTo, setFromTo] = useState(null)
    const [dateCache, setDateCache] = useState(null)
    const [selected, setSelected] = React.useState([])
    const username = useSelector(state => state.auth.username)
    const token = useSelector(state => state.auth.token)

    const onSubmit = (from, to, value) => {

        let fromDate = from
        let toDate = to

        if (dateCache) {

            fromDate = dateCache.from
            toDate = dateCache.to
            setDateCache(null)
        }

        axios.get('/user/profitLoss/' + fromDate + '/' + toDate + '/' + value, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {

                if (response.data.success) {
                    setBufferRows(response.data.data.profitLoss)
                    setCommission(response.data.data.com)

                    const cur_date = {
                        from: from,
                        to: to
                    }
                    setFromTo(cur_date)

                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {

        const columns = bufferRows[0] && Object.keys(bufferRows[0])
        let newRows = search ? bufferRows.filter(bufferRow =>
            columns.some(
                column => bufferRow[column]?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
            )) : bufferRows

        setRows(newRows)

    }, [search, bufferRows])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const showBets = (event, market, eventId) => {

        const cur_event = {
            event: event,
            market: market,
            eventId: eventId,
            username: username
        }
        setEventInfo(cur_event)
        setBets(true)
        setDateCache(fromTo)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => {
                return {
                    event: n.event,
                    event_id: n.event_id,
                    market: n.market
                }
            });
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, event_id, market, type) => {
        const selectedIndex = selected.findIndex(row => row.event === event && row.event_id === event_id && row.market === market ? true : false)
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, {
                event: event,
                event_id: event_id,
                market: market,
                type: type
            });
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const filter = [
        { value: 'All', label: 'All' },
        { value: 'Cricket', label: 'Cricket' },
        { value: 'Soccer', label: 'Soccer' },
        { value: 'Tennis', label: 'Tennis' }
    ]

    const columns = [
        {
            id: "event",
            align: "center",
            minWidth: 500
        },
        {
            id: "market",
            align: "center",
            minWidth: 300
        },
        {
            id: "Profit_Loss",
            align: "center",
        },
        {
            id: "commission",
            align: "center",
        },
        {
            id: "winner",
            align: "center",
        },
        {
            id: "settled_at",
            align: "center",
            minWidth: 200
        },
        {
            id: "action",
            align: "center",
            minWidth: 200
        }
    ]
    const isSelected = (event, event_id, market) => selected.findIndex(row => row.event === event && row.event_id === event_id && row.market === market ? true : false) !== -1;

    let table = <>
        <DateTime
            filter={filter}
            onSubmit={onSubmit}
            name='Profit Loss Listing'
            search={search}
            dateCache={dateCache}
        />
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>

                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={handleSearch}
                        inputProps={{ "aria-label": "search" }}
                    />
                </div>
                <TableContainer>
                    <EnhancedTableToolbar numSelected={selected.length} selected={selected} />
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            numSelected={selected ? selected.length : 0}
                            onSelectAllClick={handleSelectAllClick}
                            com={commission}
                        />
                        <TableBody>
                            {!rows?.length ? (
                                <TableRow>
                                    <TableCell className={classes.cell} colSpan={columns.length}>No data</TableCell>
                                </TableRow>
                            ) : null}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    const isItemSelected = isSelected(row.event, row.event_id, row.market);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => handleClick(row.event, row.event_id, row.market, row.type)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.market + row.event}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        padding="none"
                                                        className={column.id === 'event' ? classes.cell : classes.smallCell}
                                                    >
                                                        {column.id === 'settled_at' ?
                                                            moment(value).format('DD-MM-YYYY HH:mm:ss')
                                                            : column.id === 'action' ?
                                                                <div>
                                                                    <p
                                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation()
                                                                            showBets(row.event, row.market, row.event_id)
                                                                        }}
                                                                    >Show Bets</p>
                                                                </div>
                                                                : formatCoins(value)}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    );
                                })}
                            <TableRow>
                                <TableCell colSpan='2'></TableCell>
                                <TableCell className={classes.totalCell} align='center'>Total</TableCell>
                                <TableCell className={classes.totalCell} style={{ color: commission?.totalProfitLoss >= 0 ? 'green' : 'red' }} align='center'>{formatCoins(commission?.totalProfitLoss)}</TableCell>
                                <TableCell className={classes.totalCell} style={{ color: commission?.totalCommission >= 0 ? 'green' : 'red' }} align='center'>{formatCoins(commission?.totalCommission)}</TableCell>
                                <TableCell colSpan='3'></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.pagination}
                />
            </Paper>
        </div>
    </>

    if (bets) {
        table =
            <Paper className={classes.paper}>
                <BetHistory eventInfo={eventInfo} fromTo={fromTo} flag={1} />
            </Paper>
    }

    return (
        <div className={classes.container}>
            <div>{bets ?
                <p style={{ display: 'flex', justifyContent: 'space-between', marginRight: '12px' }}>
                    <span style={{ fontWeight: 'bold' }}>Bet history</span>
                    <span style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => {
                        setBets(false)
                    }}>Back</span>
                </p> : null}
            </div>
            {table}
        </div>
    )
}