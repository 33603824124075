import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  Grow,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../axios-instance/backendAPI";
import * as actions from "../../../../store/actions/index";
import logo from '../../../../assets/images/logo1.png'
import Modal from '../../../UI/Modal/Modal';
import Exposure from "./Exposure/Exposure";
import Marquee from 'react-fast-marquee';
import { formatCoins } from "../../../../helpers/common";
import DrawerLab from "../../Drawer/Drawer";

export default function Navbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [user, setUser] = useState({});
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [dropdownList, setDropdownList] = useState([])
  const balance = useSelector((state) => state.update.balance)
  const exposure = useSelector((state) => state.update.exposure)
  const token = useSelector(state => state.auth.token)
  const usertype = useSelector(state => state.auth.usertype)
  const username = useSelector(state => state.auth.username)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const onClose = () => {
    setShow(false);
  };
  const getMessage = () => {

    axios.get('/user/getMessage', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.data.success) {
          setMessage(response.data.message)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleRedirect = (slug) => {
    history.push(slug)
    handleToggle()
  }

  const accountStatement = () => {
    history.push({
      pathname: "/accountStatement",
      search: "?username=" + username + "&usertype=" + usertype,
    });
    handleToggle()
  };

  const handleLogout = () => {
    dispatch(actions.logout())
    handleToggle()
  }

  useEffect(() => {
    getMessage()
    setInterval(()=>{
      getMessage()
    },5 * 60 * 1000)
  }, [])

  useEffect(() => {

    let reportlist = [
      { name: 'Account Statement', onCall: accountStatement },
      { name: 'Chip Summary', onCall: ()=>handleRedirect('/chipSummary') },
      { name: 'Client P & L', onCall: ()=>handleRedirect('/clientPL') },
      { name: 'User P & L', onCall: ()=>handleRedirect('/userPL') },
      { name: 'Fancy Stakes', onCall: ()=>handleRedirect('/fancyStakes') },
      { name: 'Profit & Loss', onCall: ()=>handleRedirect('/profitLoss') },
      { name: 'Bet & History', onCall: ()=>handleRedirect('/bethistory') },
      { name: 'Market Analysis', onCall: ()=>handleRedirect('/runningMarketAnalysis')},
      { name: 'Change Password', onCall: ()=>handleRedirect('/changePassword')}
    ];
  
    if (usertype === '5') {
      reportlist = [
        { name: 'Account Statement', onCall: accountStatement },
        { name: 'Profit & Loss', onCall: ()=>handleRedirect('/profitLoss') },
        { name: 'Bet & History', onCall: ()=>handleRedirect('/bethistory') },
        {name: 'Market Analysis', onCall: ()=>handleRedirect('/runningMarketAnalysis')},
        { name: 'Change Password', onCall: ()=>handleRedirect('/changePassword')}
      ];
    }
  
    if (usertype !== '1') {
      reportlist.push({ name: 'Activity', onCall: ()=>handleRedirect('/activity') })
    }

    setDropdownList(reportlist)

    axios
      .get("/user/info", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.success || !res.data.success && res.data.password_changed){
          const user = res.data.user;
          const isAdmin = user.username.toLowerCase() === 'admin';
          setUser(user);
          dispatch(actions.updateBalanceOrExposure(
            isAdmin ? user.coins_generated : user.balance,
            isAdmin ?  user.coins_withdrawn : user.exposure
          ));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {

    const isAdmin = user?.username?.toLowerCase() === 'admin';

    if(isAdmin)
      setUser(prevUser => ({
        ...prevUser,
        coins_generated: balance,
        coins_withdrawn: exposure
      }));
    else
      setUser(prevUser => ({
        ...prevUser,
        balance: balance,
        exposure: exposure
      }));
  }, [balance , exposure])

  const isAdmin = user.username?.toLowerCase() === "admin"

  return (
    <div className={classes.root}>
      <Modal open={show} onClose={onClose}>
        <Exposure />
      </Modal>
      <AppBar position="fixed" className={classes.AppBar}>
        <Toolbar className={classes.toolbar} color="inherit">
          <Grid item sm={12} xs={12} className={classes.container}>
            <Toolbar className={classes.tool}>
              <DrawerLab/>
              <Grid>
                <div className={classes.mainLogo}  
                  onClick={() => {
                    history.push("/dashboard");
                }}>
                 <img src={logo} className={classes.logo}/>
                 {/* <span className={classes.homeIcon}>Maxx100</span> */}
                </div>
              </Grid>
              <Grid className={classes.grow}>
                <div className={classes.marquee}>
                  <Marquee pauseOnHover={true} speed={30}>
                    {message}
                  </Marquee>
                </div>
              </Grid>
              <div className={classes.coins_info}>
                <div className={classes.box}>
                  <span>
                    <span className={classes.info}>{isAdmin ? "Chips_G: " : "Bal: " }</span>
                    <span className={classes.info} style={{marginRight: '6px'}}>{formatCoins(user[isAdmin ? "coins_generated" : "balance"])}</span>
                  </span>
                </div>
                <div className={classes.lower_info}>
                  <div className={classes.box} onClick={() => user?.usertype==='5' && setShow(true)}>
                    <span className={classes.innerBox}>
                      <span className={classes.info} style={user?.usertype==='5' ? {cursor: 'pointer'} : {}}>{isAdmin ? "Chips_W: " : "Exp: "}</span>
                      <span className={classes.info} style={user?.usertype==='5' ? {cursor: 'pointer'} : {}}>{formatCoins(user[isAdmin ? "coins_withdrawn" : "exposure"])}</span>
                    </span>
                  </div>
                  <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.username}
                  >
                    <span>{user.username}</span>
                    <ArrowDropDownIcon className={open ? classes.iconOpen : classes.iconClosed} />
                  </Button>
                </div>
              </div>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={1}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          className={classes.menu}
                          autoFocusItem={open}
                          id="menu-list-grow"
                        >
                          {dropdownList.map(item=>{
                            return <MenuItem key={item.name} onClick={item.onCall} className={classes.menuItem}>
                              {item.name}
                            </MenuItem>
                          })}
                          <MenuItem className={classes.lastMenuItem} onClick={handleLogout}>
                            Logout    
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Toolbar>
          </Grid>
        </Toolbar>
        {message !== ' ' ? <div className={classes.mobileMarquee}>
          <Marquee pauseOnHover={true} speed={30}>
            {message}
          </Marquee>
        </div>: <div className={classes.mobileMarquee}>
        </div>}
      </AppBar>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  AppBar: {
    backgroundColor: "var(--heading)",
    boxShadow: 'none',
    [theme.breakpoints.up("sm")]: {
      padding: '10px'
    },
  },
  homeIcon: {
    color: '#ea09a2',
    fontSize: 'var(--large_font)',
    fontWeight: 700,
    marginRight: '5px',
  },
  logo: {
    height: '55px',
    [theme.breakpoints.down("sm")]: {
      height: '52px'
    },
  },
  marquee: {
    width: '350px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid black',
    marginLeft: '5px',
    fontSize: 'var(--large_font)',
    backgroundColor: '#fff',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMarquee: {
    width: '100%',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'var(--heading)',
    color: '#ea09a2',
    fontWeight: 700,
    fontSize: 'var(--medium_font)',
    zIndex: '-1',
    fontSize: 'var(--small_font)',
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  container: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      minHeight: "37px",
    },
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  coins_info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  lower_info: {
    display: 'flex',
  },
  mainLogo: {
    justifyContent: "left",
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonFontSize: {
    fontSize: "11px",
    color: "#a1a1a1",
  },
  box: {
    fontWeight: '700',
    padding: '3px',
  },
  innerBox: {
    [theme.breakpoints.down("sm")]: {
      position: 'relative',
      top: '1px'
    },
  },
  iconOpen: {
    transform: 'rotate(180deg)',
    marginBottom: '2px'
  },
  iconClosed: {
    transform: 'rotate(0deg)',
    marginBottom: '2px'
  },
  info: {
    color: 'var(--header_color)',
    fontSize: 'var(--large_font)',
    marginBottom: '4px',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)',
    },
  },
  username: {
    fontWeight: '700',
    color: 'var(--header_color)',
    fontSize: 'var(--large_font)',
    textTransform: 'lowercase',
    padding: '3px',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)'
    },
  },
  appBar: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "fixed",
      top: "50px",
      backgroundColor: "#fff",
      borderTop: "1px solid black",
      minHeight: "30px",
    },
  },
  tool: {
    minHeight: "30px",
    color: "black",
  },
  menu: {
    zIndex: "10",
  },
  menuItem: {
    fontSize: 'var(--medium_font)',
    color: 'blue',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)'
    },
    "&:hover": {
      background: 'var(--heading)',
    }
  },
  lastMenuItem: {
    borderTop: '1px solid grey',
    fontSize: 'var(--large_font)',
    color: 'blue',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)'
    },
    "&:hover": {
      background: 'var(--heading)',
    }
  },
}));
